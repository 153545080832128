import { Component, OnDestroy, Inject, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { LoginService } from '../../services/login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnDestroy, OnInit, AfterViewInit {
  public navItems;
  public sidebarMinimized = false;
  private changes: MutationObserver;
  public element: HTMLElement;
  public toggleBtn = false;
  @ViewChild('appSidebar', { static: false })  appSidebar: ElementRef;
  @ViewChild('appSidebarNav', { static: false })  appSidebarNav: ElementRef;

  constructor(
    private authService: LoginService,
    private router: Router,
    @Inject(DOCUMENT) _document?: any
  ) {
    this.changes = new MutationObserver((mutations) => {
      //  console.log('Changes','Changes');

      this.sidebarMinimized = _document.body.classList.contains(
        'sidebar-minimized'
      );
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }


  ngOnInit(): void {

    let customNav: any = null;
    setTimeout(() => {
      customNav = document.getElementsByClassName("custom-nav");
      console.log(customNav);
      Array.from(customNav).forEach((menu: any) => {
        menu.addEventListener('click', () => {
          let openMenu: any = document.getElementsByClassName("open");
          let classListFromMenu: any = Array.from(menu.classList);
          let idClickMenu: string = "";
          classListFromMenu.forEach(cls => {
            if (String(cls).includes("id-")) {
              idClickMenu = cls.split("-")[1];
            }
          });

          Array.from(openMenu).forEach((element: any) => {
            // custom-nav-child
            if (!element.classList.contains('custom-nav-child') && !element.classList.contains('custom-nav-subchild')) {
              let classList: any = Array.from(element.classList);
              let idClick: string = "";
              classList.forEach(cls => {
                if (String(cls).includes("id-")) {
                  idClick = cls.split("-")[1];
                }
              });

              if (idClickMenu != idClick) {
                element.classList.remove("open");
              }

            }

          });
          //menu.classList.add("open");

        }, false);
      });
    }, 1000);

    //menu clientes posicion 0 de navArray
    if (!this.authService.getPermisoAltaClientes() && !this.authService.getReporteListaCliente() &&
      !this.authService.getPermisoClientesNuevos() && !this.authService.getPermisoRepEdoCuenta()) {
      // navItems[0].attributes = { style: 'display: none', disabled: true };
      navItems[0].attributes = { style: 'display: none', disabled: true };
    }


    if (!this.authService.getPermisoAltaClientes()) {
      navItems[0].children[0].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getReporteListaCliente()) {
      navItems[0].children[1].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoClientesNuevos()) {
      navItems[0].children[2].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoRepEdoCuenta()) {
      navItems[0].children[3].attributes = { disabled: true, style: 'display: none' };
    }


    // if (!this.authService.getPermisoImagenes() && !this.authService.getPermisoOfertas()) {
    //   //navItems[2].attributes = { style: 'display: none', disabled: true }; // ya no aplica porque no existe ese menu solo era título
    // }
    // Menu imagenes faltantes
    if (!this.authService.getPermisoImagenes()) {
      navItems[1].attributes = { style: 'display: none', disabled: true };
    }

    // Opciones de marketing menu
    if (!this.authService.getPermisoOfertas()) {
      for (let index = 0; index <navItems[2].children.length; index++) {
       navItems[2].attributes = { style: 'display: none', disabled: true };
       navItems[2].children[index].attributes = {
          disabled: true,
          style: 'display: none',
        };
      }
    }

    //MENU claves genericas
    if (!this.authService.getPermisoClavesGenericas()) {
     navItems[3].attributes = { style: 'display: none', disabled: true };
    }

    // const user = this.authService.getUserData();
    // console.log('Items');
    // console.log(navItems);
    // console.log(user);
    // console.log(this.authService.getPermisoReporteVentas2());


    if (!this.authService.getPermisoReporteVentas2() &&
        !this.authService.getPermisoReporteAbc() &&
        !this.authService.getVentasMarcas() &&
        !this.authService.getPermisosAnalisisVentas() &&
        !this.authService.getReporteVentasMesPorCliente()) {
      navItems[4].children[0].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }

    if (!this.authService.getPermisoReporteVentas2()) {
      navItems[4].children[0].children[0].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }


    if (!this.authService.getVentasMarcas()) {
      navItems[4].children[0].children[1].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }



    if (!this.authService.getPermisoReporteAbc()) {
      navItems[4].children[0].children[2].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }
    if (!this.authService.getReporteVentasMesPorCliente()) {
      navItems[4].children[0].children[3].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }

    if (!this.authService.getPermisosAnalisisVentas()) {
      navItems[4].children[0].children[4].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }

    if (!this.authService.getReporteCanjesKabiCoins()) {
      navItems[4].children[1].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }

    if (true) {// !this.authService.getPermisoReporteClientes()
      navItems[4].children[2].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }
    if (!this.authService.getPermisoParticipacion()) {
      navItems[4].children[3].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getPermisoReporteEncuesta()) {
      navItems[4].children[4].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }
    if (!this.authService.getetPermisoListaPrecios()) {
      navItems[4].children[5].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }
    if (!this.authService.getClientesKabiCoins()) {
      navItems[4].children[6].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getPermisoRH()) {
      // navItems[8].attributes = { style: 'display: none', disabled: true };
      navItems[5].attributes = { style: 'display: none', disabled: true };
    }
    if (!this.authService.getReportePedidosEspeciales() &&
        !this.authService.getPermisosArticulosInsumos() &&
        !this.authService.getPermisoArticulosWeb() &&
        !this.authService.getPermisoDepartamentosWeb() &&
        !this.authService.getPermisoMarcasWeb() &&
        !this.authService.getPermisoGruposWeb() &&
        !this.authService.getPermisosBackorder() &&
        !this.authService.getReporteMatriz()
        ) {
      // navItems[10].attributes = { style: 'display: none', disabled: true };
      navItems[6].attributes = { style: 'display: none', disabled: true };
    }
    if (!this.authService.getReportePedidosEspeciales()) {
      navItems[6].children[0].attributes = { style: 'display: none', disabled: true };
    }


    if (!this.authService.getPermisosArticulosInsumos()) {
      navItems[6].children[1].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }


    if (!this.authService.getPermisoArticulosWeb()) {
      navItems[6].children[2].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getPermisoDepartamentosWeb()) {
      navItems[6].children[3].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }
    if (!this.authService.getPermisoMarcasWeb()) {
      navItems[6].children[4].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getPermisoGruposWeb()) {
      navItems[6].children[5].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getPermisosBackorder()) {
      navItems[6].children[6].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }
    if (!this.authService.getReporteMatriz()) {
      navItems[6].children[7].attributes = {
        disabled: true,
        style: 'display: none',
      };
    }

    if (!this.authService.getArticulosProduccion() && !this.authService.getArticulosUPC() && !this.authService.getAlmacenDevoluciones()) {
      navItems[7].attributes = { style: 'display: none', disabled: true };
    }

    if (!this.authService.getArticulosProduccion()) {
      navItems[7].children[0].attributes = { style: 'display: none', disabled: true };
    }
    if (!this.authService.getAlmacenDevoluciones()) {
      navItems[7].children[1].attributes = { style: 'display: none', disabled: true };
    }

    if (!this.authService.getArticulosUPC()) {
      navItems[7].children[2].attributes = { style: 'display: none', disabled: true };
    }



    if (!this.authService.getPermisosProgramacionPagos()) {
      // navItems[14].attributes = { disabled: true, style: 'display: none' };
      navItems[8].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoDepWebMulti()
      && !this.authService.getPermisoGrupoWebMulti()
      && !this.authService.getPermisoMarcasWebMulti()
      && !this.authService.getPermisoMargenVenMult()
      && !this.authService.getPermisoSeguimientoPedidos()
      && !this.authService.getPermisosTablaPedidosMul()
      && !this.authService.getPermisoSeguimientoPedidosInventario()
      && !this.authService.getClintesCondionados()) {
      //navItems[16].attributes = { disabled: true, style: 'display: none' };
      navItems[9].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoDepWebMulti()) {
      navItems[9].children[0].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoGrupoWebMulti()) {
      navItems[9].children[1].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoMarcasWebMulti()) {
      navItems[9].children[2].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoMargenVenMult()) {
      navItems[9].children[3].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoSeguimientoPedidos()) {
      navItems[9].children[4].attributes = { disabled: true, style: 'display: none' };
    }
    if(!this.authService.getPermisoSeguimientoPedidosInventario()){
      navItems[9].children[5].attributes = { disabled: true, style: 'display: none' };

    }

    if (!this.authService.getPermisosTablaPedidosMul()) {
      navItems[9].children[6].attributes = { disabled: true, style: 'display: none' };
    }
    if (!this.authService.getClintesCondionados()) {
      navItems[9].children[7].attributes = { disabled: true, style: 'display: none' };
    }
   /*Inventario */
    if (!this.authService.getPermisoInventario() && !this.authService.getResumenCosteo()) {
      navItems[10].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoInventario()) {
      navItems[10].children[0].attributes = {
        style: 'display: none',
        disabled: true,
      };
      navItems[10].children[1].attributes = {
          style: 'display: none',
          disabled: true,
        };
    }
    if (!this.authService.getResumenCosteo()) {
      navItems[10].children[2].attributes = {
        style: 'display: none',
        disabled: true,
      };
    }
/* End Inventario */

   if (!this.authService.getLicenciasZafiro() && !this.authService.getPermisoFirma()) {
      navItems[11].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getLicenciasZafiro()) {
      navItems[11].children[0].attributes = { disabled: true, style: 'display: none' };
    }

    if (!this.authService.getPermisoFirma()) {
      navItems[11].children[1].attributes = { disabled: true, style: 'display: none' };
    }
    // reporte_ventas_cliente_por_mes
    this.navItems = navItems;
    console.log('is nav items',this.navItems);

  }
  ngOnDestroy(): void {
    this.changes.disconnect();
  }
  ngAfterViewInit(): void {

    // console.log('#293', this.appSidebarNav);

  //   this.appSidebarNav.nativeElement.forEach((div: ElementRef) =>{
  //     console.log('is div', div);
  //     // div.nativeElement.style.display='none'
  //  });
    // console.log('293', this.appSidebar);

  }

  logOut() {
    if (this.authService.logOut()) {
      this.router.navigate(['/login']);
    }
  }
}


