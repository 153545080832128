import { version } from 'process';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../services/login/login.service';
import { Response, Data } from '../../views/claves-genericas/model-claves-genericas/model-claves-genericas'
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  constructor(
    private http: HttpClient,
    private authService: LoginService
  ) { }

  report(fechaInicial: Date, fechaFinal: Date) {
    const data = {
      empresa: environment.empresaId,
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
      version: environment.version,
      opcion: 'show'
    }
    return this.http.post<Data>(environment.apiUrl + 'productos/createGenericKeyDesarrollo.php', data).pipe(map((data) => data)).toPromise();
  }

  createGenericKey(clave: any, noParte: any, descripcion: any, moneda: any, precio: any, costo: any) {
    const user = this.authService.getUserData();
    const data = {
      empresa: environment.empresaId,
      clave: clave,
      noParte: noParte,
      descripcion: descripcion,
      moneda: moneda,
      precio: precio,
      costo: costo,
      quien : user.correo,
      opcion: 'insert'
    };
    return this.http.post<Response>(environment.apiUrl + 'productos/createGenericKeyDesarrollo.php', data).pipe(map(data => data)).toPromise();
  }

  validateGenericKey(validacion: string, key: any) {
    const data = {
      empresa: environment.empresaId,
      clave: key,
      validacion
    };
    return this.http.post<Response>(environment.apiUrl + 'productos/genericKeyValidations.php', data).pipe(map(data => data)).toPromise();
  }

  validateNoParte(validacion: string, noParte: any) {
    const data = {
      empresa: environment.empresaId,
      noParte,
      validacion
    };
    return this.http.post<Response>(environment.apiUrl + 'productos/genericKeyValidations.php', data).pipe(map(data => data)).toPromise();
  }

  exportToExcel = (claves_genericas: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      empresaId: environment.empresaId,
      claves_genericas: claves_genericas,
      columnas: columnas,
      opcion: 'to_export',
    };
    return this.http.post<any>(environment.apiUrl + 'productos/createGenericKeyDesarrollo.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_claves_genericas_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
