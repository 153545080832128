import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

interface Respuesta {
  message: string;
  jwt?: string;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})

export class LoginService {

  constructor(private http: HttpClient) { }

  login(email: string, pass: string) {
    const data = {
      empresa: environment.empresaId,
      email: email,
      password: pass,
    };
    return this.http
      .post<Respuesta>(environment.apiUrl + 'login/loginAdminKabik.php', data)
      .pipe(
        map((data) => {
          if (data.jwt) {
            // console.log(jwt_decode(data.jwt));
            localStorage.setItem('KabikSession', data.jwt);
            return data;
          } else {
            return data;
          }
        })
      )
      .toPromise();
  }

  loginCliente(email: string, pass: string) {
    const data = {
      empresa: environment.empresaId,
      email,
      password: pass,
      option: 'LoginUserDevolucion',
      opcion: '1'
    };
    return this.http
      .post<Respuesta>(environment.apiUrl + 'login/loginUsuariosDevoluciones.php', data) //login para generar Guias de devolucion de articulos
      .pipe(
        map((data) => {
          if (data.jwt) {
            // console.log(data.jwt);
            localStorage.setItem('KabikSession', data.jwt);
            return data;
          } else {
            return data;
          }
        })
      )
      .toPromise();

  }

  isLogged() {
    const jwt = localStorage.getItem('KabikSession');
    if (jwt) {
      const decoded = jwt_decode(jwt);
      const date = new Date(0);
      date.setUTCSeconds(decoded.exp);
      return date.valueOf() > new Date().valueOf();
    } else {
      return false;
    }
  }

  logOut() {
    localStorage.removeItem('KabikSession');
    window.location.reload();
    return true;
  }

  getUserData() {
    const jwt = jwt_decode(localStorage.getItem('KabikSession'));
    return jwt;
  }
  getPermisos() {
    return this.getUserData().data;
  }
  getPermisoClavesGenericas() {
    const user = this.getUserData();
    if (user.clavesGenericas === '1') {
      return true;
    } else {
      return false;
    }
  }
  getPermisoReporteVentas() {
    const user = this.getUserData();
    if (user.reporte_ventas === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoImagenes() {
    const user = this.getUserData();
    // console.log(user);
    if (user.imagenes === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoImagenesArticulos() {
    const user = this.getUserData();
    // console.log(user);
    if (user.articulos_imagenes === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoOfertas() {
    const user = this.getUserData();
    // console.log(user);
    if (user.ofertas === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoAltaClientes() {
    const user = this.getUserData();
    // console.log(user);
    if (user.clientes === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoClientesNuevos() {
    const user = this.getUserData();
    // console.log(user);
    if (user.clientes_nuevos === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoReporteClientes() {
    const user = this.getUserData();
    if (user.reporte_clientes === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoRH() {
    const user = this.getUserData();
    if (user.recursos_humanos === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoReporteEncuesta() {
    const user = this.getUserData();
    if (user.reporte_encuesta === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoReporteImgSub() {
    const user = this.getUserData();
    if (user.reporte_img_subidas === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoParticipacion() {
    const user = this.getUserData();

    if (user.reporte_participacion === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoReporteAbc() {
    const user = this.getUserData();
    if (user.reporte_abc === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisosAnalisisVentas() {
    const user = this.getUserData();
    // console.log(user);

    if (user.data.REP_ANALISIS_VENTAS === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoReporteAbc2() {
    const user = this.getUserData();
    if (user.reporte_abc2 === '1') {
      return true;
    } else {
      return false;
    }
  }

  getReporteListaCliente() {
    const user = this.getUserData();
    if (user.reporte_lista_clientes === '1') {
      return true;
    } else {
      return false;
    }
  }

  getReportePedidosEspeciales() {
    const user = this.getUserData();
    if (user.reporte_pedidos_especiales === '1') {
      return true;
    } else {
      return false;
    }
  }

  getPermisoGenerarGuia() {
    const user = this.getUserData();
    if (user.generar_guia === "1") return true;
    else return false;
  }

  getPermisoConsultarGuia() {
    const user = this.getUserData();
    if (user.consulta_guia === "1") return true;
    else return false;
  }

  getPermisoConsultarCobertura() {
    const user = this.getUserData();
    if (user.consulta_cobertura === "1") return true;
    else return false;
  }
  getPermisoReporteVentas2() {
    const user = this.getUserData();
    return user.reporte_ventas2 === '1' ? true : false;
  }
  getPermisoReporteVentas2Admin() {
    const user = this.getUserData();
    return user.reporte_ventas2_admin === '1' ? true : false;
  }

  getPermisoGenerarGuiaEmpresas() {
    const user = this.getUserData();
    return user.generar_guia_empresas === '1' ? true : false;
  }

  getPermisoGenerarGuiaGarantias() {
    const user = this.getUserData();
    return user.generar_guia_garantias === '1' ? true : false;
  }

  getPermisoCatalogoDirecciones() {
    const user = this.getUserData();
    return user.catologo_direcciones === '1' ? true : false;
  }

  getPermisoDepartamentosWeb() {
    const user = this.getUserData();
    return user.departamentos_web === '1' ? true : false;
  }

  getPermisoMarcasWeb() {
    const user = this.getUserData();
    return user.marcas_web === '1' ? true : false;
  }
  getPermisoArticulosWeb() {
    const user = this.getUserData();
    return user.articulos_web === '1' ? true : false;
  }

  getPermisoGruposWeb() {
    const user = this.getUserData();
    return user.grupos_web === '1' ? true : false;
  }

  getReporteCanjesKabiCoins() {
    const user = this.getUserData();
    return user.reporte_kabikcoins === '1' ? true : false;
  }


  getetPermisoListaPrecios() {
    const user = this.getUserData();
    return user.lista_precios === '1' ? true : false;
  }

  getArticulosProduccion() {
    const user = this.getUserData();
    return user.articulos_produccion === '1' ? true : false;
  }
  getClientesKabiCoins() {
    const user = this.getUserData();
    return user.clientes_kbcoins === '1' ? true : false;
  }
  getVentasMarcas() {
    const user = this.getUserData();
    return user.reporte_ventas_marca_vendedor === '1' ? true : false;
  }

  getPermisosBackorder() {
    const user = this.getUserData();
    return user.backorder === '1' ? true : false;
  }

  getPermisosArticulosInsumos() {
    const user = this.getUserData();
    return user.articulos_insumos === '1' ? true : false;
  }

  getPermisosProgramacionPagos() {
    const user = this.getUserData();
    return user.programacion_pagos === '1' ? true : false;
  }

  getPermisosUsuarios() {
    const user = this.getUserData();
    return user.programacion_pagos === '1' ? true : false;
  }

  getPermisoDepWebMulti() {
    const user = this.getUserData();
    return user.departamento_web_multi === '1' ? true : false;
  }

  getPermisoGrupoWebMulti() {
    const user = this.getUserData();
    return user.grupo_web_multi === '1' ? true : false;
  }

  getPermisoMarcasWebMulti() {
    const user = this.getUserData();
    return user.marcas_web_multi === '1' ? true : false;
  }

  getPermisoMargenVenMult() {
    const user = this.getUserData();
    return user.margen_vent_dep === '1' ? true : false;
  }

  getPermisoSeguimientoPedidos() {
    const user = this.getUserData();
    return user.seguimiento_pedidos === '1' ? true : false;
  }

  getPermisosTablaPedidosMul() {
    const user = this.getUserData();
    return user.permisos_seguimiento_pedidos === '1' ? true : false;
  }

  getLicenciasZafiro() {
    const user = this.getUserData();
    return user.licencias_zafiro === '1' ? true : false;
  }

  getPermisoFirma() {
    const user = this.getUserData();
    return user.permiso_firma === '1' ? true : false;
  }

  getPermisoInventario() {
    const user = this.getUserData();
    return user.permiso_periodos_inventario === '1' ? true : false;
  }
  getPermisoRepEdoCuenta() {
    const user = this.getUserData();
    return user.rep_edo_cuenta === '1' ? true : false;
  }
  getArticulosWeb() {
    const user = this.getUserData();
    return user.articulos_web === '1' ? true : false;
  }
  getMarcasWeb() {
    const user = this.getUserData();
    return user.marcas_web === '1' ? true : false;
  }
  getDeparamentosWeb() {
    const user = this.getUserData();
    return user.departamentos_web === '1' ? true : false;
  }
  getGruposWeb() {
    const user = this.getUserData();
    return user.grupos_web === '1' ? true : false;
  }
   getResumenCosteo(){
    const user = this.getUserData();
    return user.rep_resumen_costeo === '1' ? true : false;
   }
   getPermisoEditarInsumo(){
    const user = this.getUserData();
    return user.permiso_editar_insumos === '1' ? true : false;
   }
   getArticulosUPC(){
    const user = this.getUserData();
    return user.almacen_articulos_upc === '1' ? true : false;
   }

   getAlmacenDevoluciones(){
    const user = this.getUserData();
    return user.almacen_devoluciones === '1' ? true : false;
   }
   getReporteVentasMesPorCliente(){
    const user = this.getUserData();
    return user.reporte_ventas_cliente_por_mes === '1' ? true : false;

   }
   getClintesCondionados(){
    const user = this.getUserData();
    return user.clientes_condicionados === '1' ? true : false;

   }
   getPermisoSeguimientoPedidosInventario() {
    const user = this.getUserData();
    return user.seguimiento_pedidos_inventario === '1' ? true : false;
  }
  getReporteMatriz() {
    const user = this.getUserData();
    return user.reporte_matriz === '1' ? true : false;
   }

}
