import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import {
  NgbDate,
  NgbPeriod,
  NgbCalendar,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, map } from 'rxjs/operators';

import {
  ImagesService,
  ResponceOfertas,
  Respuesta,
} from '../../services/images/images.service';
import { MatTableDataSource } from '@angular/material/table';
import { ViewsExImagenComponent } from './views-ex-imagen/views-ex-imagen.component';
import { ModalImagenesExternasComponent } from './modal-imagenes-externas/modal-imagenes-externas.component';
import { of } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface LIST {
  position: number;
  imagen: string;
  descripcion: number;
  posicion: string;
  lugar: number;
  tipo: string;
  url: string;
}
@Component({
  selector: 'app-imagenes-externas',
  templateUrl: './imagenes-externas.component.html',
  styleUrls: ['./imagenes-externas.component.scss'],
})
export class ImagenesExternasComponent implements OnInit, OnDestroy {
  @ViewChild('createImageModal', { static: false })
  public createImageModal: ModalDirective;
  @ViewChild('editImageModal', { static: false })
  private mySubscription: any;
  files: File;
  ruta = environment.apiUrl + 'imagenes_pagina/Kabik/Pagina/';
  formData = new FormData();
  images: any;
  imageEdit: any;
  posiciones: any;
  fechaFinal: any;
  fechaInicio: any;
  minDate: NgbDate;
  minDateExp: NgbDate;
  period: NgbPeriod;
  externas: string[] = ['Carousel principal', 'Banner inferior'];
  internas: string[] = [
    'Carousel principal',
    'Banner derecho',
    'Banner izquierdo',
  ];
  editarArchivoImagen: boolean = false;
  descripcionEdit: any;
  public list: LIST[] = [];
  isLoadingResults = false;
  loadUpdate: boolean;
  loadDelete: boolean;
  dataSource: MatTableDataSource<any>;
  public selection: SelectionModel<any>;
  public listImagenes: any = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'select',
    'imagen',
    'descripcion',
    'posicion',
    'lugar',
    'vigencia',
  ];

  constructor(
    private imagesService: ImagesService,
    private calendar: NgbCalendar,
    public modalImg: MatDialog,
    public modalAdd: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    // this.isLoadingResults = true;
    this.imagesService.currentImage.subscribe((res) => {
      if (res) {
        this.loadData();
      }
    });
  }

  private loadData() {
    this.isLoadingResults = true;
    this.imagesService.getImages('imagen').then((res) => { //Se envia el tipo de elemento si es IMG o MP4
      let index = 0;
      //this.dataSource = res.images;
      this.images = res.images;

      // console.log(this.dataSource);
      this.images.forEach((element) => {
        const json = {
          position: index,
          id: element.id,
          imagen: element.imagen,
          posicion: element.posicion,
          descripcion: element.descripcion,
          lugar: element.lugar,
          tipo: element.tipo,
          url: element.url,
          fechaInicial: element.FechaInicial,
          fechaFinal: element.FechaFinal
        };

        this.list[index] = json;

        index++;
      });

      const imagenesEliminar = this.imagesService.eliminarImagenFecha(this.list);
      // console.log(imagenesEliminar);console.log()
      if(imagenesEliminar.expiraronBanners.length > 0 ){
          this.imagesService.eliminarBanners(imagenesEliminar.expiraronBanners).subscribe(res => {
            if(res.error === false){

              this.openSnackBar('Se elimino de forma automatica ('+imagenesEliminar.expiraronBanners.length+') banners');
              //  this.imagesService.updateImageObservable(res.mensaje);
            }
          });
        }
      this.dataSource = new MatTableDataSource(imagenesEliminar.actualBanners);
      this.selection = new SelectionModel<any>(true, this.listImagenes);
      this.isLoadingResults = false;
    });
  }
  // private eliminarImagenesFecha(){
  //   const imagenesEliminar = this.imagesService.eliminarImagenFecha(this.list);
  //   // if(imagenesEliminar.length > 0 ){
  //   //   this.imagesService.eliminarBanners(imagenesEliminar).subscribe(res => {
  //   //     if(res.error === false){
  //   //        this.openSnackBar('Se eliminaron de forma automatica ('+imagenesEliminar.length+') banners');
  //   //        this.imagesService.updateImageObservable(res.mensaje);
  //   //     }
  //   //   });
  //   //  }
  // }

  public eliminarImagen() {

    const item = this.listImagenes;
    const title = '¿Desea eliminar (' + (this.listImagenes.length ) + ') banners?';
    Swal.fire({
      type: 'warning',
      title: title,// '¿Eliminar esta imagen?',
      text: 'Este cambio no se pude revertir, ¿desea continuar?',
      confirmButtonText: 'Si, Eliminar!',
      showCancelButton: true,
      cancelButtonText: 'No, Cancelar!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      focusConfirm: false,
      focusCancel: true,
    })
      .then((res) => {
        if (res.value) {
          if (item) {
            console.log(item);

             this.imagesService.eliminarBanners(item).subscribe(res => {
               if(res.error === false){
                Swal.fire({
                            type: 'success',
                            title: 'Correcto',
                            text: 'Se ha eliminado correctamente la imagen',
                 });
                 this.imagesService.updateImageObservable(res.mensaje);
                 this.router.navigateByUrl('/marketing/imagenes-externas');
               } else {
                  throw new Error('Ocurrio un error al eliminar: '+ res.mensaje);
               }
             })
            // this.formData.append('empresa', environment.empresaId.toString());
            // this.formData.append('id', item.id);
            // console.log(this.formData.getAll('imagenNombre'));

            // this.imagesService
            //   .eliminarImagen(this.formData)
            //   .pipe(
            //     mergeMap((respImg) => {
            //       return this.imagesService.deleteAllOferta(item.url).pipe(
            //         map((respOfertas) => {
            //           return { respOfertas, respImg };
            //         })
            //       );
            //     })
            //   )
            //   .subscribe(
            //     (combineResp) => {
            //       console.log(combineResp.respImg, combineResp.respOfertas);
            //       if (
            //         (combineResp.respImg.message === 'true' &&
            //           item.tipo !== 'oferta') ||
            //         (combineResp.respImg.message === 'true' &&
            //           item.tipo === 'oferta' &&
            //           combineResp.respOfertas.respuesta)
            //       ) {
            //         this.imagesService.updateImageObservable(
            //           combineResp.respImg
            //         );
            //         Swal.fire({
            //           type: 'success',
            //           title: 'Correcto',
            //           text: 'Se ha eliminado correctamente la imagen',
            //         });
            //         this.router.navigateByUrl('/imagenes-externas');
            //       } else {
            //         throw new Error('Ocurrio un error al eliminar');
            //       }
            //     },
            //     (err) => {
            //       console.log(err);
            //       Swal.fire({
            //         type: 'error',
            //         title: 'Oops...',
            //         text: `Algo salió mal, ${err.message}`,
            //         footer: 'Comunicate para resolver el problema',
            //       });
            //     }
            //   );
          }
        }
      })
      .catch((e) => {
        console.log({ error: e });
      })
      .finally(() => {});
  }

  public openModalEdit() {

    const form = this.listImagenes[0];
    /* console.log(form); */
    /* filtra el objeto buscando el index y añade cada valor del objeto en uno nuevo  */
    const imageData = Object.keys(this.images)
      .filter((curr) => curr === form.position.toString())
      .reduce((obj, key) => {
        obj = this.images[key];
        return obj;
      }, {});

    const dialogEdit = this.modalImg.open(ModalImagenesExternasComponent, {
      width: '60%',
      data: imageData,
      disableClose: true,
    });

    dialogEdit.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl('/marketing/imagenes-externas');
      }
    });
  }

  public openModalImage(element: any) {
    const dialogRef = this.modalImg.open(ViewsExImagenComponent, {
      data: {
        src: this.ruta + element.imagen,
        lugar: element.lugar,
        posicion: element.posicion,
        descripcion: element.descripcion,
      },
    });

    dialogRef.beforeClosed().subscribe((result) => {});
  }

  public openModalAdd(): void {
    const dialogRef = this.modalAdd.open(ModalImagenesExternasComponent, {
      width: '60%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl('/marketing/imagenes-externas');
      }
    });
  }

  ngOnDestroy() {}

  cargarFechas() {
    this.minDate = this.calendar.getToday();
    this.minDateExp = this.calendar.getNext(this.minDate);
    this.period = 'm';
  }
  fechaToString(date: NgbDate) {
    const day = date.day < 10 ? '0' + date.day.toString() : date.day.toString();
    const month =
      date.month < 10 ? '0' + date.month.toString() : date.month.toString();
    const year = date.year.toString();

    return year + month + day;
  }

  changeSelectLugar(lugar: string) {
    if (lugar === 'interno') {
      this.posiciones = this.internas;
    }
    if (lugar === 'externo') {
      this.posiciones = this.externas;
    }
  }

  /* editarModalHide() {
    this.editarArchivoImagen = false;
    this.editImageModal.hide();
  }
 */
  /*   editarModalImage(imagen) {
    this.imageEdit = imagen;
    this.descripcionEdit = this.imageEdit.descripcion;
    console.log(this.imageEdit);
    this.fechaFinal = this.convertirFecha(this.imageEdit.FechaFinal);
    this.fechaInicio = this.convertirFecha(this.imageEdit.FechaInicial);
    console.log(this.fechaInicio);
    this.editImageModal.show();
  } */

  convertirFecha(fecha: string): NgbDateStruct {
    if (!fecha) {
      return null;
    }

    const parts = fecha.split('-');
    const day = parts[2].substr(0, 2);
    return { year: +parts[0], month: +parts[1], day: +day };
  }

  setFile(event) {
    this.files = event.target.files;
    if (this.files[0].size > 2000000) {
      event.target.files = null;
      Swal.fire({
        type: 'error',
        title: 'No debe exceder 2M de tamaño',
      });
    } else {
      this.formData.append('file', this.files[0], this.files[0].name);
    }
  }

  public subirImagen(form: any) {
    if (form.valid) {
      this.formData.append('empresa', '2');
      this.formData.append('posicion', form.control.value.posicion);
      this.formData.append('lugar', form.control.value.lugar);
      this.formData.append('descripcion', form.control.value.descripcion);
      this.formData.append('fechaFinal', this.fechaToString(this.fechaFinal));
      this.formData.append(
        'fechaInicial',
        this.fechaToString(this.fechaInicio)
      );

      this.imagesService
        .subirImagen(this.formData)
        .then((res) => {
          // console.log(res);
          if (res.message === 'true') {
            this.imagesService.updateImageObservable(res);
            this.createImageModal.hide();
            Swal.fire({
              type: 'success',
              title: 'Correcto',
              text: 'Se ha subido correctamente la imagen',
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  public isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }
  masterToggle() {
    this.listImagenes = [];
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        this.listImagenes = [...this.listImagenes, row];
        this.selection.select(row);
      });
  }


  public selectedImagenes(event?: any, row?: any) {

    if (event.checked) {
      this.listImagenes = [...this.listImagenes, row];
    } else {

      this.removeToArray(row);
    }
    event = event ? this.selection.toggle(row) : null;
    return event;
  }
  filtrar(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  private removeToArray(row: any): void {

    let contador = 0;
    this.listImagenes.forEach(categoria => {
      if (categoria === row) {
        if (this.listImagenes.length <= 1) {
          this.listImagenes = [];
        } else {
          const cont = contador <= 1 ? 1 : contador;
          this.listImagenes.splice(contador, 1);
        }
      }
      contador++;
    });
  }
  private openSnackBar(mensaje: string) {
    this.snackBar.open(mensaje, 'Ok', {
      duration: 2200,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
