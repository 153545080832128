import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Presupuesto,
         DataClientes,
         Grupos,
         DataGrupo,
         DataDepartamento,
         DataReporteVentasDos,
         Cliente,
         Departamento,
         DataReporteVentasTres,
         DataVendedores,
         vendendores,
         DataSucursales,
         sucursal,
         DataMarcas,
         marcas, DataAlmacenes,
         Almacen} from '../models/ABC';
import { DataVentaMarcas,DataGeneric } from '../models/VentasMarcas';
import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ABCService {

  constructor(private http: HttpClient) { }

  public recalcular = new BehaviorSubject<boolean>(false);
  recalcular$ = this.recalcular.asObservable();



  private api: string = environment.apiUrl;
  getPresupuesto = (sucursalId:string) => {
    const data = {
      option: 'getweb_PresupuestoKB',
      sucursalId,
      empresaId: environment.empresaId,
    }
    return this.http.post<Presupuesto>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(map(response => {
      return response;
    }));
  }
  public recalcularAbc$(data: boolean) {
    this.recalcular.next(data);
  }
  setPresupuesto = (puntoVenta: number, venta: number, margen: number, utilidad: number): Observable<any> => {
    const data = {
      option: 'setspRepAnaDeVentas',
      puntoVenta,
      venta,
      margen,
      utilidad,
      empresaID: environment.empresaId
    }
    return this.http.post<any>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        if (response.error === false) {
          this.recalcular.next(true);
        }
        return response;
      }));
  }
  getReporteAbcUno = (allVendedores: boolean, listVendedores: vendendores[],allSucursales:boolean,  sucursales:sucursal[],fechaInicio:Date,fechaFinal:Date,porPeriodo:number): Observable<any> => {
    const data = {
      option: 'getReporteAbcUnoOld', // 'getReporteAbcUno2',
      empresaID: environment.empresaId,
      allVendedores,
      listVendedores,
      allSucursales,
      sucursales,
      fechaInicio,
      fechaFinal,
      porPeriodo,


    }
    return this.http.post<any>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3), catchError(this.handleError), map(response => {
      return response;
    }));
  }
  getExcelAbc = (columnas: string[], filas: any) => {
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      option: 'getExcelABC',
      columnas,
      filas,
      empresaID: environment.empresaId
    }
    return this.http
      .post<any>(
        environment.apiUrl +
        '/reportes/reporte-abc/get_reporte_abc.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_ABC_uno_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }
  getExcelAbcdos = (columnas: string[], filas: any) => {
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      option: 'getExcelABC2',
      columnas,
      filas,
      empresaID: environment.empresaId
    }
    return this.http
      .post<any>(
        environment.apiUrl +
        '/reportes/reporte-abc/get_reporte_abc.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_ABC_dos_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }
  getClientesAbcDos = () => {
    const data = {
      option: "getVentasAbcClientes",
      empresaId: environment.empresaId,
    }
    return this.http.post<DataClientes>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }
  getGruposAbcDos = (clienteId:number) => {
    const data = {
      option: "getVentasAbcFiltrosGrupos",
      empresaId: environment.empresaId,
      clienteId
    }
    return this.http.post<DataGrupo>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }

  getDepartamentosAbcDos = (clienteId:number) => {
    const data = {
      option: "getVentasAbcFiltrosDepartamentos",
      empresaId: environment.empresaId,
      clienteId
    }
    return this.http.post<DataDepartamento>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }
  getReporteAbcDos = (cliente:Cliente[], allClientes: boolean, departamento: Departamento[], allDepartamentos: boolean,grupo: Grupos[], allGrupos: boolean, allSucursales: boolean,  sucursales: sucursal[]
    ) => {
    const data = {
      option: 'getDataAbcData',
      empresaId: environment.empresaId,
      cliente,
      allClientes,
      departamento,
      allDepartamentos,
      grupo,
      allGrupos,
      allSucursales,
      sucursales
      }
    return this.http.post<DataReporteVentasDos>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }
  getVendedoresAbc = () => {
    const data = {
      option: "getVentasAbcVendedores",
      empresaId: environment.empresaId,
    }
    return this.http.post<DataVendedores>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }

  getSucursales = () => {
      const data = {
        option: 'getSucursales',
        empresaID: environment.empresaId
      };
      return this.http.post<DataSucursales>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
        catchError(this.handleError), map(response => {
          return response;
       }));
  }
  getAlmacenes (sucursales: any,all=true){
    let data={};
    if(all){
       data = {
        option: 'getAlmcenes',
        empresaID: environment.empresaId,
        sucursales,
        all: true
      };
    }else{
       data = {
        option: 'getAlmcenes',
        empresaID: environment.empresaId,
        sucursales
      };
    }

    return this.http.post<DataAlmacenes>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
     }));
  }
  getReporteAbcTres = (
                       allClientes: boolean,
                       allDepartamentos: boolean,
                       allGrupos: boolean,
                       allVendedores : boolean,
                       allSucursales :boolean,
                       allMarcas:boolean,
                       tipoReporte: number,
                       cliente: Cliente[],
                       departamento: Departamento[],
                       grupo: Grupos[],
                       listVendedores: vendendores[],
                       sucursales:sucursal[],
                       listMarcas: marcas[],
                       fechaInicial,
                       fechaFinal
                      ) =>
  {
    const data = {
      option: 'getDataAbcTres3',
      empresaId: environment.empresaId,
      cliente,
      allClientes,
      departamento,
      allDepartamentos,
      allSucursales,
      grupo,
      allGrupos,
      tipoReporte,
      allVendedores,
      listVendedores,
      sucursales,
      allMarcas,
      listMarcas,
      fechaInicial,
      fechaFinal,
    }
    return this.http.post<DataReporteVentasTres>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }
  getExcelAbcTres = (columnas: string[], filas: any, tipoReporte:number,columnasMesArray:any,sumaTotales?:any) => {
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      option: 'getExcelABCTres',
      columnas,
      filas,
      empresaID: environment.empresaId,
       tipoReporte,
       columnasMesArray,
       sumaTotales
    }
    return this.http
      .post<any>(
        environment.apiUrl +
        '/reportes/reporte-abc/get_reporte_abc.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_ABC_tres_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }

  getMarcasAbc = () => {
    const data = {
      option: "getMarcas",
      empresaId: environment.empresaId,
    }
    return this.http.post<DataMarcas>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
     }));

  }

  getReporteVentasMarcas =(
    allClientes: boolean,
    allDepartamentos: boolean,
    allGrupos: boolean,
    allVendedores : boolean,
    allSucursales :boolean,
    allMarcas:boolean,
    tipoReporte: number,
    cliente: Cliente[],
    departamento: Departamento[],
    grupo: Grupos[],
    listVendedores: vendendores[],
    sucursales: sucursal[],
    listMarcas: marcas[],
    fechaInicial,
    fechaFinal
  )=> {

    const data = {
      option: 'get_webReporteVentasMarcas2',
      empresaId: environment.empresaId,
      cliente,
      allClientes,
      departamento,
      allDepartamentos,
      allSucursales,
      allMarcas,
      grupo,
      allGrupos,
      tipoReporte,
      allVendedores,
      listVendedores,
      sucursales,
      listMarcas,
      fechaInicial,
      fechaFinal,
      version: environment.version
    };

    return this.http.post<DataVentaMarcas>(this.api + '/reportes/reporte-abc/get_reporte_ventas_marcas.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }

  getExportVentaMarcas = (data: DataVentaMarcas) =>{

    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data_ = {
      option: 'setExcelReporteVentaMarcas',
      data,
      empresaId: environment.empresaId,
      // columnas,
      // filas,
      // empresaID: environment.empresaId,
      //  tipoReporte,
      //  columnasMesArray,
      //  sumaTotales
    }
    return this.http
      .post<any>(
        environment.apiUrl +
        '/reportes/reporte-abc/get_reporte_ventas_marcas.php',
        data_,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Ventas_de_marca_por_vendedor_kabik_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }

  getExportMatriz = (data: DataGeneric) =>{

    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data_ = {
      option: 'setExcelReporteMatriz',
      data,
      empresaId: environment.empresaId,
    }
    return this.http
      .post<any>(
        environment.apiUrl +
        'reportes/reporte-matriz/matriz.php',
        data_,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_Matriz' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }

  getPermisoPeriodo = () => {
    const data = {
      option: "getPermisosPeriodo",
      empresaId: environment.empresaId,
    }
    return this.http.post<DataVendedores>(this.api + '/reportes/reporte-abc/get_reporte_abc.php', data).pipe(retry(3),
      catchError(this.handleError), map(response => {
        return response;
      }));
  }

  getReporteMatriz=(
    allClientes: boolean,
    allDepartamentos: boolean,
    allGrupos: boolean,
    allVendedores : boolean,
    allSucursales :boolean,
    allMarcas:boolean,
    allAlmacenes:boolean,
    tipoReporte: number,
    cliente: Cliente[],
    departamento: Departamento[],
    grupo: Grupos[],
    listVendedores: vendendores[],
    sucursales: sucursal[],
    listMarcas: marcas[],
    almacenes: Almacen[],
    fechaInicial,
    fechaFinal
  )=> {

    const data = {
      option: 'getMatriz',
      empresaId: environment.empresaId,
      cliente,
      allClientes,
      departamento,
      allDepartamentos,
      allSucursales,
      allMarcas,
      grupo,
      allGrupos,
      tipoReporte,
      allVendedores,
      listVendedores,
      sucursales,
      listMarcas,
      fechaInicial,
      fechaFinal,
      version: environment.version,
      allAlmacenes,
      almacenes
    };

    return this.http.post<DataGeneric>(this.api + 'reportes/reporte-matriz/matriz.php', data).pipe(retry(3),
    catchError(this.handleError), map(response => {
      return response;
    }));
  }


  private handleError = (error: HttpErrorResponse | any) => {

    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }
}
