import {Component,OnInit,Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment.prod';
import { LoginService  } from '../../../services/login/login.service';
import Swal from 'sweetalert2';
import {
  debounceTime,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AlmacenService } from '../../../services/Almacen/almacen.service';

export interface DataFile {
  message?: string;
  archivos?: Array<{}>;
}

export interface User {
  name: string;
}

@Component({
  selector: 'app-otros-proveedores-guias',
  templateUrl: './otros-proveedores-guias.component.html',
  styleUrls: ['./otros-proveedores-guias.component.scss'],
})
export class otrosProveedoresGuiasComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private almacenService: AlmacenService
  ) {

  }
  public form: FormGroup;
  public files: File = null;
  public dataFile: File;
  public formData = new FormData();
  private unsubscribe$ = new Subject<void>();
  public  listProveedores = [
    {value: 'DHL'},
    {value: 'INBOX'},
    {value: 'PAQUETE EXPRESS'},
    {value: 'ESTAFETA'},
    {value: 'OTRA PAQUETERIA'},
  ];
  @Input() aditionalInfo = null;
  @Input() data = null;
  @Output() closeModalGuia: EventEmitter<boolean> = new EventEmitter();

  ngOnInit() {
    console.log('<<>>>',this.data)
    this.spinner.hide('spinner-guia');
    this.buildForm();
  }
  private buildForm = (): void => {
      this.form = this.formBuilder.group({
        proveedor: ['',[Validators.required,Validators.minLength(3)]],
        otroProveedor: [''],
        guia: ['',[Validators.required, Validators.minLength(5)]]
      });
  }
   /**
   * Metodo para seleccionar el file del dropzone
   * @param event evento que emite el DropZone, devuelve los datos del archivo
   */
   public fileSelect = (event): void => {
    this.files = event.addedFiles;
    if (this.files[0].size > 2000000) {
      Swal.fire({
        type: 'warning',
        title: 'Alta de cliente',
        html: 'El tamaño del archivo debe ser menor a 2MB',
        confirmButtonText: 'Aceptar',
        footer:
          'Contáctanos&nbsp;<a href="mailto:incidencias@kabik.mx">incidencias@kabik.mx</a>',
      });
      this.files = null;
      return;
    }
    if (this.files !== null) {
      this.setFromData();
      this.successDropZone();
    }
  };

  public setFromData = () => {
    this.dataFile = this.files;
  };

  private successDropZone = () => {
    const dropzone = document.getElementById('drop-zone-rfc');
    dropzone.classList.add('drop-zone-success');
    dropzone.classList.remove('drop-zone-error');
  };

  private errorDropZone = () => {
    // Se deshabilita para que no marque como requerido el documento.
    if (this.files === null) {
      const dropzone = document.getElementById('drop-zone-rfc');
      dropzone.classList.add('drop-zone-error');
      dropzone.classList.remove('drop-zone-success');
    }
  };
  public fileRemove = (event): void => {
    this.files = null;
    const dropzone = document.getElementById('drop-zone-rfc');
    dropzone.classList.remove('drop-zone-success');
    dropzone.classList.remove('drop-zone-error');
  };
  public onSubmit = (): void => {
    // event.preventDefault();
     const data = this.getFile();
     if (this.form.valid) {
       this.spinner.show('spinner-guia');
       this.almacenService
       .setProveedorGuia(this.formData)
       .pipe(takeUntil(this.unsubscribe$))
       .subscribe((responseUploadFile) => {
         this.spinner.hide('spinner-fiscal');
         if (responseUploadFile.status === 'success') {
           Swal.fire({
             type: 'success',
             title: 'Otras paqueterias',
             text: 'La informacion se guardo con exito.',
             confirmButtonText: 'Aceptar',
           });
           this.closeModalGuia.emit(true);
         } else {
           Swal.fire({
             type: 'error',
             title: 'Otras paqueterias',
             html:
               'Lo sentimos, ocurrio un error al guardar la informacion.<br/><br/>Error : ' +
               responseUploadFile.message,
             confirmButtonText: 'Aceptar',
             footer:
               'Contáctanos&nbsp;<a href="mailto:incidencias@kabik.mx">incidencias@kabik.mx</a>',
           });
         }
       });

     }
  }


  /**
   * Obtenemos el archivo file y lo almacenamos en un FromData con el RFC y el nombre de la empresa
   */
  public getFile = (): void => {
    const proveedorGuia =  this.form.get('proveedor').value == 'OTRA PAQUETERIA' ? this.form.get('otroProveedor').value:this.form.get('proveedor').value ;
    const empresaId = environment.empresaId;
    const file: File = this.dataFile;
    this.formData.append('files[]', file[0], file[0].name);
    this.formData.append('empresa', String(empresaId));
    this.formData.append('proveedorGuia', proveedorGuia);
    this.formData.append('almacenId', this.aditionalInfo.almacen);
    this.formData.append('pedidoId',  this.aditionalInfo.pedido);
    this.formData.append('guia', this.form.get('guia').value );
    this.formData.append('option','setOtrosEnvios');
    this.formData.append('quien',this.loginService.getUserData().correo);
    this.formData.append('cliente',this.aditionalInfo.cliente);
    this.formData.append('tipo','Pedido');
  };
}
