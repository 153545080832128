import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Data } from '../../views/devoluciones/model-devoluciones/model-devoluciones';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class DevolucionesService {

  constructor(private http: HttpClient) { }

  public show(serie: string, folio: number) {
    const data = {
      empresa: environment.empresaId,
      serie,
      folio,
      opcion: 'show'
    }
    return this.http.post<Data>(environment.apiUrl + 'admin/api_devoluciones.php', data).pipe(map((data) => data)).toPromise();
  }

  exportToExcel = (devoluciones: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      empresaId: environment.empresaId,
      devoluciones: devoluciones,
      columnas: columnas,
      opcion: 'to_export',
    };
    return this.http.post<any>(environment.apiUrl + 'admin/api_devoluciones.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_devoluciones_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
