import { Injectable } from '@angular/core';
import { map, retry,catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as FileSaver from 'file-saver';

interface VentasClientesPorMes {
  Data: any[];
  Columnas: Columnas[];
 }
 interface Columnas {
   field: string;
   width: string;
   type: string;
   align: string;
   index?: number;

 }

@Injectable({
  providedIn: 'root'
})

export class ReporteVentasClienteXMesService {

  constructor(private http: HttpClient) { }

  getVentasClientePorMes = (fechaInicial: Date, fechaFinal: Date, option:string = 'getReporteVentas'): Observable<VentasClientesPorMes> => {
    const data = {
      option,
      fechaInicial,
      fechaFinal,
      empresaID: environment.empresaId
    }
    return this.http.post<VentasClientesPorMes>(environment.apiUrl + 'reportes/reporte-ventas-cliente-por-mes/reporte-ventas-cliente-por-mes.php', data).pipe(
      retry(3), map(response => { return response;}));
  }
  exportToExcel = (data: any, columnas: string[], name:string) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const items = {
      empresaId: environment.empresaId,
      data,
      columnas: columnas,
      option: 'to_export',
    };
    return this.http.post<any>(environment.apiUrl + 'reportes/reporte-ventas-cliente-por-mes/reporte-ventas-cliente-por-mes.php', items, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], name + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
