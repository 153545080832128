import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Data, Response } from '../../views/articulos-insumos/model-articulos-insumos/model-articulos-insumos';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class ArticulosInsumosService {

  constructor(private http: HttpClient) { }

  show(ClaveArticulo: string) {
    const data = {
      EmpresaID: environment.empresaId,
      ClaveArticulo,
      opcion: 'show'
    }
    return this.http.post<Data>(environment.apiUrl + 'productos/api_articulos_insumos.php', data).pipe(map((data) => data)).toPromise();
  }

  update(ClaveArticulo: string, TipoArticulo: string, ManejaSeries: number, CodigoBarras: string , ModuloActualiza?:'Compras'|'Almacen') {
    const data = {
      EmpresaID: environment.empresaId,
      ClaveArticulo,
      TipoArticulo,
      ManejaSeries,
      CodigoBarras,
      ModuloActualiza,
      opcion: 'update'
    }
    return this.http.post<Response>(environment.apiUrl + 'productos/api_articulos_insumos.php', data).pipe(map((data) => data));
  }

  exportToExcel = (articulos: any, columnas: string[], name:string = 'reporte_articulos_insumos_') => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      empresaId: environment.empresaId,
      articulos: articulos,
      columnas: columnas,
      opcion: 'to_export',
    };
    return this.http.post<any>(environment.apiUrl + 'productos/api_articulos_insumos.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], name + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
