import { clienteID } from './../views/clientes/account-status/account-datos-fiscales/account-datos-fiscales.component';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import * as FileSaver from 'file-saver';

export interface Clientes {
  message: string;
  clientes: Array<{}>;
}
export interface Respuesta {
  message: string;
  cliente: {};
}
export interface FileResponse {
  message: string;
  archivos: Array<{}>;
}
export interface Vendedores {
  message: string;
  vendedores?: Array<{}>;
  tipoPrecio?:any;
}

export interface AltaCliente {
  message: string;
  numeroCliente?: any;
  error?: any;
}
export interface ResponseGeneric {
  estatus: string;
  mensaje?: string;
  error?: boolean;
  code:string
}

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  private clientSource = new BehaviorSubject<Respuesta>({
    message: '',
    cliente: {}
  });
  currentClient = this.clientSource.asObservable();

  constructor(private http: HttpClient) { }

  public getClientes() {
    const data = {
      empresaId: environment.empresaId
    };
    return this.http
      .post<Clientes>(environment.apiUrl + 'clients/getWebClients.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  public updateCliente(cliente) {
    this.clientSource.next(cliente);
  }

  public getSingleCliente(rfc,clienteID=0) {
    const data = {
      empresaId: environment.empresaId,
      clienteRfc: rfc,
      clienteID:clienteID
    };
    return this.http
      .post<Respuesta>(
        environment.apiUrl + 'clients/getSingleWebClient.php',
        data
      )
      .pipe(map(data => data))
      .toPromise();
  }

  public deleteCliente(rfc, correo,id=0) {
    const data = {
      empresaId: environment.empresaId,
      clienteRfc: rfc,
      clienteCorreo: correo,
      id
    };
    return this.http
      .post<Respuesta>(environment.apiUrl + 'clients/deleteWebClient.php', data)
      .pipe(map(data => data))
      .toPromise();
  }

  public getFiles(rfc) {
    const data = {
      rfc: rfc,
      empresa: 'Kabik'
    };
    return this.http
      .post<FileResponse>(
        environment.apiUrl + 'clients/getUploadsWebFiles.php',
        data
      )
      .pipe(map(data => data))
      .toPromise();
  }

  public deleteFiles(rfc) {
    const data = {
      rfc: rfc,
      empresa: 'Kabik'
    };
    return this.http
      .post<FileResponse>(
        environment.apiUrl + 'clients/deleteWebFiles.php',
        data
      )
      .pipe(map(data => data))
      .toPromise();
  }

  public renameFiles(rfc, nameArchivo, clienteID) {
    const data = {
      rfc: rfc,
      nameArchivo,
      clienteID,
      empresa: 'Kabik'
    };
    return this.http
      .post<FileResponse>(
        environment.apiUrl + 'clients/renameWebFiles.php',
        data
      )
      .pipe(map(data => data))
      .toPromise();
  }

  public getVendedores() {
    const data = {
      empresa: environment.empresaId
    };
    return this.http
      .post<Vendedores>(environment.apiUrl + 'clients/get_vendedores.php', data)
      .pipe(map(data1 => data1))
      .toPromise();
  }

  public updateRegistro(
    id: number,
    datosPersonales: any
  ){

    const data = {
      empresaID: environment.empresaId,
      id,
      option:"setPreRegistroClienteUpdate",
      datosPersonales,
      isAdmin: true
    };

    return this.http
    .post<ResponseGeneric>(
      environment.apiUrl + '/Servicios-web/Cliente/server.php',
      data
    )
    .pipe(map(data1 => data1))
    .toPromise();

  }

  public altaCliente(
    clienteId: any,
    sucursal: string,
    vendedor: string,
    correo: string,
    tipoPrecio: string,
    cfdi: string,
    regimenFiscal: string
  ) {
    const data = {
      empresa: environment.empresaId,
      clienteId,
      sucursal,
      vendedor,
      correo,
      tipoPrecio,
      cfdi,
      regimenFiscal,
    };
    return this.http
      // .post<AltaCliente>(environment.apiUrl + 'clients/alta_cliente.php', data)
      .post<AltaCliente>(environment.apiUrl + 'clients/alta_cliente.php', data)
      .pipe(map(data1 => data1))
      .toPromise();
  }

  public altaClienteAccesos(
    clienteId: any,
    sucursal: string,
    nombre: string,
    contra: string,
    correo: string
  ) {
    const data = {
      empresa: environment.empresaId,
      clienteId,
      sucursal,
      nombre,
      contra,
      correo
    };
    return this.http
      .post<AltaCliente>(
        environment.apiUrl + 'clients/alta_accesos_cliente.php',
        data
      )
      .pipe(map(data1 => data1))
      .toPromise();
  }

  verificarClienteExistente(rfc: string) {
    const data = {
      empresa: environment.empresaId,
      rfc
    };
    return this.http
      .post<AltaCliente>(
        environment.apiUrl + 'clients/verificarClienteExistente.php',
        data
      )
      .pipe(map(data1 => data1))
      .toPromise();
  }

  getClientesNuevos(fechaInicio: string, fechaFin: string) {

    const data = {
      opcion: 'getClientesNuevos',
      empresa: environment.empresaId,
      fechaFin,
      fechaInicio
    }

    return this.http.post<any>(environment.apiUrl + '/clients/alta_clientes.php', data).pipe(retry(3), catchError(this.handleError), map(response => {
      return response;
    }));

  }


  getExcelAbc = (columnas: string[], filas: any) => {

    const data = {
      opcion: 'setClientesExcel',
      columnas,
      filas,
      empresaID: environment.empresaId
    }
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        environment.apiUrl +
        '/clients/alta_clientes.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
          const blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
          const file = new File([blob], 'Reporte_clientes_nuevos_kabik' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '_' + f.getSeconds() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  }


  private handleError = (error: HttpErrorResponse | any) => {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text:
        'Lo sentimos, Ocurrio un error de comunicación, Favor de contactarse con el administrador del sitio Incidencias@kabik.com.mx' + error.message
    });
    return throwError(error);
  }
}
