import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginService } from '../../services/login/login.service';
import * as FileSaver from 'file-saver';

export interface Articulos {
  Id: number;
  EmpresaID: number;
  ArticuloID: string;
  ClaveArticulo: string;
  Descripcion: string;
  Quien: string;
  Cuando: Date;
  MostrarBackOrder: number;
  MostrarUltimasPiezas: number;
}

export interface Data {
  message: string | object;
  response: Articulos[];
  columns: string[];
}

export interface Response {
  message: string;
}

@Injectable({
  providedIn: 'root'
})

export class UltimasPiezasService {

  constructor(
    private http: HttpClient,
    private login: LoginService
  ) { }

  public show() {
    const data = {
      opcion: 'show',
      empresa: environment.empresaId
    }
    return this.http.post<Data>(environment.apiUrl + 'admin/api_articulos_backorder.php', data).pipe(map((data) => data)).toPromise();
  }

  public insert(ClaveArticulo: string, MostrarBackOrder: number, MostrarUltimasPiezas: number) {
    const data = {
      opcion: 'insert',
      EmpresaID: environment.empresaId,
      ClaveArticulo,
      MostrarBackOrder,
      MostrarUltimasPiezas,
      Quien: this.login.getUserData().correo
    }
    return this.http.post<Response>(environment.apiUrl + 'admin/api_articulos_backorder.php', data).pipe(map((data) => data)).toPromise();
  }

  public update(Id: number, MostrarBackOrder: number, MostrarUltimasPiezas: number) {
    const data = {
      opcion: 'update',
      Id,
      EmpresaID: environment.empresaId,
      MostrarBackOrder,
      MostrarUltimasPiezas
    }
    return this.http.post<Response>(environment.apiUrl + 'admin/api_articulos_backorder.php', data).pipe(map((data) => data));
  }

  public delete(Id: any) {
    const data = {
      opcion: 'delete',
      EmpresaID: environment.empresaId,
      Id
    }
    return this.http.post<Response>(environment.apiUrl + 'admin/api_articulos_backorder.php', data).pipe(map((data) => data));
  }

  validate(clave: string) {
    const data = {
      option: 'validaClave',
      clave,
      empresa: environment.empresaId
    }
    return this.http.post<any>(environment.apiUrl + 'ofertas/OfertasAdmin.php', data).pipe(map(response => {
      return response;
    }));
  }

  exportToExcel = (articulos: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      opcion: 'to_export',
      empresaId: environment.empresaId,
      articulos: articulos,
      columnas: columnas
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_articulos_backorder.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_backorder_ultimas_piezas_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
