import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Data, Response } from '../../views/permisos/model-permisos/model-permisos';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class PermisosService {

  constructor(
    private http: HttpClient,
    private login: LoginService
  ) { }

  public show() {
    const data = {
      empresa: environment.empresaId,
      opcion: 'show'
    }
    return this.http.post<Data>(environment.apiUrl + 'admin/api_permisos.php', data).pipe(map((data) => data)).toPromise();
  }

  public update(permisos: any) {
    const data = {
      empresa: environment.empresaId,
      permisos,
      opcion: 'update'
    }
    return this.http.post<Response>(environment.apiUrl + 'admin/api_permisos.php', data).pipe(map((data) => data));
  }

  exportToExcel = (permisos: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      empresaId: environment.empresaId,
      permisos,
      columnas,
      opcion: 'to_export'
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_permisos.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_permisos_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
