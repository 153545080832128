import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(
    private http: HttpClient
  ) { }

  public show(sucursal: number, almacen: string, clavePeriodo: string, tipoReporte: string) {
    const data = {
      opcion: 'show',
      empresa: environment.empresaId,
      sucursal,
      almacen,
      clavePeriodo,
      tipoReporte
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_reporte_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  public periodos(sucursal: number, almacen: string) {
    const data = {
      opcion: 'periodos',
      empresa: environment.empresaId,
      sucursal,
      almacen
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_reporte_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  exportToExcel = (datos: any, columnas: string[], diferencias: any, columnasDiferencias: string[]) => {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      opcion: 'to_export',
      empresa: environment.empresaId,
      datos,
      columnas,
      diferencias,
      columnasDiferencias
    }

    const name = diferencias[0]['ClavePeriodo'];
    const nombreExcel = (diferencias.length > 0)?name.replace(" ","_"):'Reporte_Inventario_Kabik';

    return this.http.post<any>(environment.apiUrl + 'admin/api_reporte_inventario.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob],  nombreExcel+'.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }

}
