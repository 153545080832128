import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ReporteService } from './../../../services/inventario/reporte/reporte.service';
import { ABCService } from '../../ABC/utils/services/ABC.service';
import { DataSucursales, sucursal } from '../../ABC/utils/models/ABC';
import { PeriodoInventarioService } from '../../../services/inventario/periodo/periodo-inventario.service';

@Component({
  selector: 'app-reporte-inventario',
  templateUrl: './reporte-inventario.component.html',
  styleUrls: ['./reporte-inventario.component.scss']
})

export class ReporteInventarioComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  matTableDataSource: MatTableDataSource<any>;
  public data = [];
  public diferencias = [];
  public columns: string[] = [];
  public columnasDiferencias: string[] = [];
  public sucursales: sucursal[] = [];
  public almacenes = [];
  public periodos = [];
  public moneda: string[] = ['Costo', 'Importe', 'CostoDiferencias'];
  public progressAlmacen: boolean = false;
  public progressPeriodo: boolean = false;
  public formGroup = this.formBuilder.group({
    sucursal: [null, [Validators.required]],
    almacen: ['', [Validators.required]],
    clavePeriodo: [null, [Validators.required]],
    tipoReporte: ['', [Validators.required]]
  });

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private reporteService: ReporteService,
    private abcService: ABCService,
    private periodoInventarioService: PeriodoInventarioService
  ) {
    this.getSucursales();
  }

  ngOnInit() { }

  exportToExcel = () => {
    this.spinner.show('spinner');
    this.reporteService.exportToExcel(this.data, this.columns, this.diferencias, this.columnasDiferencias).then((res) => {
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
      });
      this.spinner.hide('spinner');
    });
  }
  filter = (event: Event) => {
    const filter = (event.target as HTMLInputElement).value;
    this.matTableDataSource.filter = filter.trim().toLowerCase();
    if (this.matTableDataSource.paginator) {
      this.matTableDataSource.paginator.firstPage();
    }
  }

  onSubmit = () => {
    if (this.formGroup.invalid) {
      Swal.fire({
        type: 'warning',
        title: 'Faltan campos por completar',
        text: 'Por favor, escribe los datos que faltan',
        confirmButtonText: 'Aceptar'
      });
      return;
    }
    this.getReporte();
  }

  getReporte = () => {
    this.spinner.show('spinner');
    this.reporteService.show(
      this.formGroup.value.sucursal,
      this.formGroup.value.almacen,
      this.formGroup.value.clavePeriodo,
      this.formGroup.value.tipoReporte
    ).then((res) => {
      if (res.message === 'true') {
        if (this.formGroup.value.tipoReporte == 'GENERAL') {
          this.matTableDataSource = new MatTableDataSource(res.response);
          this.matTableDataSource.sort = this.sort;
          this.matTableDataSource.paginator = this.paginator;
          this.data = res.response;
          this.columns = res.columns[0].Columnas.split(',');
          this.getDiferencias(res.response);
          this.getColumnasDiferencias(res.columns[0].Columnas.split(','));
        }
        if (this.formGroup.value.tipoReporte == 'USUARIO') {
          this.matTableDataSource = new MatTableDataSource(res.response);
          this.matTableDataSource.sort = this.sort;
          this.matTableDataSource.paginator = this.paginator;
          this.data = res.response;
          this.columns = res.columns;
        }
      }
      this.spinner.hide('spinner');
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message
      });
      this.spinner.hide('spinner');
    });
  }

  getDiferencias = (data: any) => {
    this.diferencias = [];
    for (let diferencia of data) {
      if (diferencia.Diferencias !== 0) {
        this.diferencias.push({
          'EmpresaID': diferencia.EmpresaID,
          'SucursalID': diferencia.SucursalID,
          'AlmacenID': diferencia.AlmacenID,
          'DepartamentoID': diferencia.DepartamentoID,
          'ArticuloID': diferencia.ArticuloID,
          'ClaveArticulo': diferencia.ClaveArticulo,
          'Descripcion': diferencia.Descripcion,
          'UPC': diferencia.UPC,
          'ClavePeriodo': diferencia.ClavePeriodo,
          'Existencias': diferencia.Existencias,
          'Conteo': diferencia.Conteo,
          'Diferencias': diferencia.Diferencias,
          'Costo': diferencia.Costo,
          'Importe': diferencia.Importe,
          'CostoDiferencias': diferencia.CostoDiferencias
        });
      }
    }
  }

  getColumnasDiferencias = (data: any) => {
    this.columnasDiferencias = [];
    for (let columna of data) {
      if (columna === 'ExistenciaActual') {
        return;
      }
      this.columnasDiferencias.push(columna);
    }
  }

  getSucursales = () => {
    this.abcService.getSucursales().subscribe((res: DataSucursales) => {
       this.sucursales = res.sucursales;
    });
  }

  getAlmacenes = (sucursal: number) => {
    this.formGroup.get('almacen').setValue('');
    this.formGroup.get('clavePeriodo').setValue(null);
    this.progressAlmacen = true;
    this.periodoInventarioService.almacen(sucursal).then((res) => {
      this.almacenes = res.response;
      this.progressAlmacen = false;
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
      this.progressAlmacen = false;
    });
  }

  getPeriodos = (sucursal: number, almacen: string) => {
    this.formGroup.get('clavePeriodo').setValue(null);
    this.progressPeriodo = true;
    this.reporteService.periodos(sucursal, almacen).then((res) => {
      this.periodos = res.response;
      this.progressPeriodo = false;
    }).catch((error) => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error.message,
        footer: 'Comunícate para resolver el problema',
      });
      this.progressPeriodo = false;
    });
  }

}
