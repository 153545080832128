import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class PeriodoInventarioService {

  constructor(
    private http: HttpClient,
    private login: LoginService
  ) { }

  public show() {
    const data = {
      opcion: 'show',
      empresa: environment.empresaId
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  public validate(ClavePeriodo: string) {
    const data = {
      opcion: 'validate',
      empresa: environment.empresaId,
      ClavePeriodo
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  public insert(SucursalID: number, AlmacenID: number, DepartamentoID: string, ClavePeriodo: string, FechaInicio: any, FechaFinal: any, ActivoPeriodo: number) {
    const data = {
      opcion: 'insert',
      EmpresaID: environment.empresaId,
      SucursalID,
      AlmacenID,
      DepartamentoID,
      ClavePeriodo,
      FechaInicio,
      FechaFinal,
      ActivoPeriodo,
      Quien: this.login.getUserData().correo
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  public update(PeriodoID: number, SucursalID: number, AlmacenID: number, DepartamentoID: string, ClavePeriodo: string, FechaInicio: any, FechaFinal: any, ActivoPeriodo: number) {
    const data = {
      opcion: 'update',
      PeriodoID,
      EmpresaID: environment.empresaId,
      SucursalID,
      AlmacenID,
      DepartamentoID,
      ClavePeriodo,
      FechaInicio,
      FechaFinal,
      ActivoPeriodo,
      Quien: this.login.getUserData().correo
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data));
  }

  public delete(Periodos: any) {
    const data = {
      opcion: 'delete',
      EmpresaID: environment.empresaId,
      Periodos
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data));
  }

  public almacen(sucursal: number) {
    const data = {
      opcion: 'stock',
      empresa: environment.empresaId,
      sucursal
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  public departamento() {
    const data = {
      opcion: 'departamentos',
      empresa: environment.empresaId
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data).pipe(map((data) => data)).toPromise();
  }

  exportToExcel = (periodos: any, columnas: string[]) => {
    const date = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const data = {
      opcion: 'to_export',
      empresa: environment.empresaId,
      periodos: periodos,
      columnas: columnas
    }
    return this.http.post<any>(environment.apiUrl + 'admin/api_periodo_inventario.php', data, { headers, responseType: 'blob' as 'json' } ).pipe(map((data) => {
      const blob = new Blob([data], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], 'reporte_periodos_inventario_kabik_' + date.getDate() + '_' + (date.getMonth() + 1) + '_' + date.getFullYear() + '_' + date.getSeconds() + '.xlsx', {
        type: 'application/vnd.ms.excel'
      });
      FileSaver.saveAs(file);
    })).toPromise();
  }
}
