import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, retry,catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import {
  RowGraficosSucursal,
  RowInversionSucursal,
  RowVentasVendedor,
  RowVentasExcluida,
  RowVentasExcluidasDesglosado,
  RowVentasVendedorPorCliente,
  RowVentasPorSucursal,
  DataResumenVentas
} from '../../views/reporte-ventas2/model/ReporteVentas';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import Swal from 'sweetalert2';
interface Ventas {
  message: string;
  ventas?: {};
}
@Injectable({
  providedIn: 'root',
})
export class ReporteVentasService {
  constructor(private http: HttpClient) { }

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    ventasIndividuales: any,
    opcion: number
  ): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { General: worksheet },
      SheetNames: ['General'],
    };                                      //Ventas 2 1                                     ventas 0
    const nombres = opcion === 1 ? this.getNombresVendedores(ventasIndividuales) : this.getNombresVendedoresV1(ventasIndividuales);

    for (let index = 0; index < ventasIndividuales.length; index++) {
      // console.log(ventasIndividuales[index]);
      const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
        ventasIndividuales[index]
      );
      XLSX.utils.book_append_sheet(workbook, worksheet1, nombres[index]);
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  exportAsExcelFileReporteClientes(ventas: any, excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ventas);
    // console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = {
      Sheets: { General: worksheet },
      SheetNames: ['General'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }



  private getNombresVendedoresV1(ventasIndividuales: any[][]) {
    const nombres = [];
    ventasIndividuales.forEach((element) => {
      nombres.push(element[0].nomvendedor);
    });
    return nombres;

  }


  private getNombresVendedores(ventasIndividuales: any[][]) {
    const nombres = [];
    console.log(ventasIndividuales);
    ventasIndividuales.forEach((element) => {
      if (element[0].ClaveArticulo === undefined) {
        if (element[0].excel === undefined) {
          nombres.push(element[0].NomVendedor.slice(0, 31));
        } else {
          nombres.push('Detalle Excluidos');
        }
      } else {
        nombres.push('Excluidos');
      }
    });
    return nombres;
  }




  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + 'kabik_' + new Date().toLocaleDateString() + EXCEL_EXTENSION
    );
  }

  public getDataVentas(fechaInicio: any, fechaFinal: any) {
    const data = {
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
      empresa: environment.empresaId,
    };
    return this.http
      .post<Ventas>(
        environment.apiUrl + 'reportes/reporte-ventas/getDataVentas.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  public getDataVentasIndividuales(vendedor: any) {
    const data = {
      empresa: environment.empresaId,
      vendedor: vendedor,
    };
    return this.http
      .post<Ventas>(
        environment.apiUrl +
        'reportes/reporte-ventas/getDataVentasIndividual.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }

  getDataVentasCliente(fechaInicio: string, fechaFinal: string) {
    const data = {
      fechaInicio: fechaInicio,
      fechaFinal: fechaFinal,
      empresa: environment.empresaId,
    };
    return this.http
      .post<Ventas>(
        environment.apiUrl +
        'reportes/reporte-ventas-clientes/getDataVentasCteResumen.php',
        data
      )
      .pipe(map((data1) => data1))
      .toPromise();
  }
  // getDataVentas2 = (fechaInicio: any, fechaFinal: any) => { /// deprecated
  //   const data = {
  //     option: 'getDataVentas',
  //     fechaInicio,
  //     fechaFinal,
  //     empresa: environment.empresaId,
  //   };

  //   return this.http
  //     .post<DataReporteVentas>(
  //       environment.apiUrl + 'reportes/reporte-ventas/getResumenVentas.php',
  //       data
  //     )
  //     .pipe(map((data1) => data1))
  //     .toPromise();
  // };

  getResumenVentas = (fechaInicial: Date, fechaFinal: Date, objetivo ): Observable<DataResumenVentas> => {

    const data = {
      option: 'getResumenVentas',
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
      empresa: environment.empresaId,
      objetivo
    };
    console.log('http post data:::', data);
    return this.http.post<DataResumenVentas>(environment.apiUrl + '/reportes/reporte-ventas/getResumenVentas.php', data).pipe(
      retry(3),
      catchError(this.handleError),
       map(response => {
        return response;
      }));
  }


  getExecelVentas = (
    isadmin: boolean,
    fechaInicial: Date,
    fechaFinal: Date,
    objetivo: number
  ) => {
    const data = {
      option: 'getExportResumenVentas',
      empresa: environment.empresaId,
      isadmin,
      fechaInicial: fechaInicial,
      fechaFinal: fechaFinal,
      objetivo
    };
    const f = new Date();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<any>(
        environment.apiUrl + '/reportes/reporte-ventas/getResumenVentas.php',
        data,
        { headers, responseType: 'blob' as 'json' }
      )
      .pipe(
        map((data1) => {
        const  blob = new Blob([data1], { type: 'application/vnd.ms.excel' });
        const file = new File([blob], 'Reporte_ventas_kabik_' + f.getDate() + '_' + (f.getMonth() + 1) + '_' + f.getFullYear() + '.xlsx', {
            type: 'application/vnd.ms.excel',
          });
          FileSaver.saveAs(file);
        })
      )
      .toPromise();
  };
  private handleError = (error: HttpErrorResponse | any) => {

    Swal.fire({
      type: 'error',
      title: 'Ventas diarias',
      html: 'Lo sentimos, ocurrio un error al consultar el reporte de ventas diarias.<br/><br/>Error: ' + error.message+'<br/><br/> Detalles: '+error.error.message,
      confirmButtonText: 'Aceptar',
      footer:
        'Contáctanos&nbsp;<a href="mailto:incidencias@kabik.mx">incidencias@kabik.mx</a>',
    });
    return throwError(error);
  }

}
